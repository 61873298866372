import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import nftStakingAbi from 'config/abi/nftStakingPool.json'
import stakeHHearnTokenAbi from 'config/abi/StakeHHearnToken.json'
import autoCompoundAbi from 'config/abi/ACPHodl.json'
import gemFighterNftABI from 'config/abi/gemFighterNFT.json'
import erc20ABI from 'config/abi/erc20.json'
import erc721CollectionABI from 'config/abi/erc721collection.json'
import hodlHandSaleABI from 'config/abi/hodlHandSale.json'
import pankacePairABI from 'config/abi/IPancakePair.json'
import multicall, { multicallv2, multicallWithOtherApi } from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import chunk from 'lodash/chunk'
import sousChefV2 from '../../config/abi/sousChefV2.json'
import sousChefV3 from '../../config/abi/sousChefV3.json'
import { useErc721CollectionContract, usePairContract } from 'hooks/useContract'
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'

const poolsACP = poolsConfig.filter((p) => p.isAutoCompounding)
const poolsWithEnd = poolsConfig.filter((p) => p.isBuyNFT !== true && p.isAutoCompounding !== true)

const startEndBlockCalls = poolsWithEnd.flatMap((poolConfig) => {
  return [
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    },
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    },
  ]
})

const startEndTimeCalls = poolsACP.flatMap((poolConfig) => {
  return [
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'startTime',
    },
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'endTime',
    },
  ]
})

const abisForBlockLimits = poolsWithEnd.flatMap((poolConfig) => {
  return [poolConfig.isNft ? nftStakingAbi : sousChefABI, poolConfig.isNft ? nftStakingAbi : sousChefABI]
})

export const fetchPoolsBlockLimits = async () => {
  // const startEndBlockRaw = await multicall(sousChefABI, startEndBlockCalls)
  const startEndBlockRaw = await multicallWithOtherApi(abisForBlockLimits, startEndBlockCalls)

  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: startBlock.toNumber(),
      endBlock: endBlock.toNumber(),
    }
  })
}

export const fetchPoolsTimeLimits = async () => {
  const startEndTimeRaw = await multicallWithOtherApi([autoCompoundAbi, autoCompoundAbi], startEndTimeCalls)

  const startEndTimesResult = startEndTimeRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return poolsACP.map((cakePoolConfig, index) => {
    const [[startTime], [endTime]] = startEndTimesResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      startTime: startTime.toNumber(),
      endTime: endTime.toNumber(),
    }
  })
}

const poolsBalanceOf = poolsConfig.map((poolConfig) => {
  return {
    address: poolConfig.stakingToken.address,
    name: 'balanceOf',
    params: [getAddress(poolConfig.contractAddress)],
  }
})

const abisForTotalStaking = poolsConfig.map((poolConfig) => {
  return poolConfig.isNft ? erc721CollectionABI : erc20ABI
})

export const fetchPoolsTotalStaking = async () => {
  // const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf)
  const poolsTotalStaked = await multicallWithOtherApi(abisForTotalStaking, poolsBalanceOf)

  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

const nftPriceCall = poolsConfig
  .filter(
    (poolConfig) =>
      poolConfig.isNft ||
      poolConfig.sousId === 0 ||
      poolConfig.sousId === 15 ||
      poolConfig.sousId === 38 ||
      poolConfig.sousId === 39 ||
      poolConfig.sousId === 40,
  )
  .map((poolConfig) => {
    let funcName
    switch (poolConfig.sousId) {
      case 0:
      case 15:
        funcName = 'getTokenAmountFromOneHand'
        break
      case 39:
        funcName = 'getPriceHODL'
        break
      case 40:
        funcName = 'getPriceHODLX'
        break
      default:
        funcName = 'getPrice'
        break
    }

    return {
      address:
        poolConfig.sousId !== 0 &&
        poolConfig.sousId !== 15 &&
        poolConfig.sousId !== 38 &&
        poolConfig.sousId !== 39 &&
        poolConfig.sousId !== 40
          ? poolConfig.stakingToken.address
          : poolConfig.contractAddress[56],
      name: funcName,
      params: [],
      poolId: poolConfig.sousId,
    }
  })

const nftDiscountCall = poolsConfig
  .filter((poolConfig) => poolConfig.sousId === 0 || poolConfig.sousId === 15)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name: 'discountRate',
    }
  })

const nftBusdPriceCall = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name: poolConfig.sousId === 38 ? 'getBUSDPrice' : poolConfig.sousId === 39 ? 'getHODLPrice' : 'getHODLXPrice',
      poolId: poolConfig.sousId,
    }
  })

const nftPromotionOnCall = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name:
        poolConfig.sousId === 38
          ? 'getPromotionOn'
          : poolConfig.sousId === 39
          ? 'getPromotionOnHodl'
          : 'getPromotionOnHodlx',
      poolId: poolConfig.sousId,
    }
  })

const nftPausedCall = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name: 'paused',
      poolId: poolConfig.sousId,
    }
  })

const reserveCall = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map((poolConfig) => {
    return {
      address:
        poolConfig.sousId === 38
          ? '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'
          : poolConfig.sousId === 39
          ? '0xad8B51b66e69EB7301d30120d7eDD4Df96CBd174'
          : '0xe253B0F3b8B7526314831f3ABF6fEdd9A44A185b',
      name: 'getReserves',
      poolId: poolConfig.sousId,
    }
  })

const abisForNftPrice = poolsConfig
  .filter(
    (poolConfig) =>
      poolConfig.isNft ||
      poolConfig.sousId === 0 ||
      poolConfig.sousId === 15 ||
      poolConfig.sousId === 38 ||
      poolConfig.sousId === 39 ||
      poolConfig.sousId === 40,
  )
  .map((poolConfig) => {
    return poolConfig.sousId !== 0 && poolConfig.sousId !== 15
      ? poolConfig.sousId !== 39 && poolConfig.sousId !== 40
        ? erc721CollectionABI
        : gemFighterNftABI
      : hodlHandSaleABI
  })

const abisForDiscountRate = poolsConfig
  .filter((poolConfig) => poolConfig.sousId === 0 || poolConfig.sousId === 15)
  .map((poolConfig) => {
    return hodlHandSaleABI
  })

const abisForBUSDPrice = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map(() => {
    return gemFighterNftABI
  })

const pancakePairABIs = poolsConfig
  .filter((poolConfig) => poolConfig.sousId >= 38 && poolConfig.sousId <= 40)
  .map(() => {
    return pankacePairABI
  })

export const fetchNftPrice = async () => {
  const nftPrice = await multicallWithOtherApi(abisForNftPrice, nftPriceCall)
  return nftPrice.map((price, i) => ({
    address: nftPriceCall[i].address,
    price:
      nftPriceCall[i].address === '0xEb61253582371d50d59d7f4DfA1BF8cC3d8E594e' ||
      nftPriceCall[i].name === 'getPriceHODL'
        ? new BigNumber(price).div(BIG_TEN.pow(9)).toJSON()
        : new BigNumber(price).div(BIG_TEN.pow(18)).toJSON(),
    poolId: nftPriceCall[i].poolId,
  }))
}

export const fetchBnbNftPrice = async () => {
  const bnbNftPriceCall = poolsConfig
    .filter((poolConfig) => poolConfig.sousId > 40 && poolConfig.earningToken.symbol === 'GF')
    .map((poolConfig) => {
      return {
        address: poolConfig.contractAddress[56],
        name: 'getNftBnbPrice',
        poolId: poolConfig.sousId,
      }
    })

  const nftPrice = await multicallWithOtherApi(
    poolsConfig
      .filter((poolConfig) => poolConfig.sousId > 40 && poolConfig.earningToken.symbol === 'GF')
      .map(() => {
        return sousChefABI
      }),
    bnbNftPriceCall,
  )
  return nftPrice.map((price, i) => ({
    address: bnbNftPriceCall[i].address,
    price: new BigNumber(price).div(BIG_TEN.pow(18)).toJSON(),
    poolId: bnbNftPriceCall[i].poolId,
  }))
}

export const fetchTokenPrice = async () => {
  const tokenReserves = await multicallWithOtherApi(pancakePairABIs, reserveCall)
  return tokenReserves.map((reserve, i) => ({
    poolId: reserveCall[i].poolId,
    price:
      reserveCall[i].poolId === 40
        ? new BigNumber(reserve[1].toString())
            .times(new BigNumber(9952.5))
            .div(new BigNumber(reserve[0].toString()))
            .toNumber() / 10000
        : new BigNumber(reserve[1].toString())
            .times(new BigNumber(9975))
            .div(new BigNumber(reserve[0].toString()))
            .toNumber() / 10000,
  }))
}

export const fetchDiscountRate = async () => {
  const discountRate = await multicallWithOtherApi(abisForDiscountRate, nftDiscountCall)
  return discountRate.map((rate, i) => ({
    address: nftDiscountCall[i].address,
    rate: Number(rate),
  }))
}

export const fetchBUSDPrice = async () => {
  const busdPrices = await multicallWithOtherApi(abisForBUSDPrice, nftBusdPriceCall)
  return busdPrices.map((price, i) => ({
    address: nftBusdPriceCall[i].address,
    busdprice: new BigNumber(price).div(BIG_TEN.pow(18)).toJSON(),
    poolId: nftBusdPriceCall[i].poolId,
  }))
}

export const fetchIsPaused = async () => {
  const isPaused = await multicallWithOtherApi(abisForBUSDPrice, nftPausedCall)
  return isPaused.map((pause, i) => ({
    address: nftBusdPriceCall[i].address,
    isPaused: pause[0],
    poolId: nftBusdPriceCall[i].poolId,
  }))
}

export const fetchPromotionOn = async () => {
  const promotionOn = await multicallWithOtherApi(abisForBUSDPrice, nftPromotionOnCall)
  return promotionOn.map((on, i) => ({
    address: nftBusdPriceCall[i].address,
    promotionOn: on[0],
    poolId: nftBusdPriceCall[i].poolId,
  }))
}

const abisForDepositFee = poolsConfig
  .filter((poolConfig) => poolConfig.depositFee)
  .map(() => {
    return stakeHHearnTokenAbi
  })

const getDepositFeeCall = poolsConfig
  .filter((poolConfig) => poolConfig.depositFee)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name: 'depositFee',
      poolId: poolConfig.sousId,
    }
  })

export const fetchPoolHasDepositFee = async () => {
  const getDepositFee = await multicallWithOtherApi(abisForDepositFee, getDepositFeeCall)
  return getDepositFee.map((fee, i) => ({
    address: getDepositFeeCall[i].address,
    hasDepositFee: Number(fee[0]) === 0,
    poolId: getDepositFeeCall[i].poolId,
  }))
}

const abisForWithdrawFee = poolsConfig
  .filter((poolConfig) => poolConfig.withdrawFee)
  .map(() => {
    return stakeHHearnTokenAbi
  })

const getWithdrawFeeCall = poolsConfig
  .filter((poolConfig) => poolConfig.withdrawFee)
  .map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[56],
      name: 'withdrawFee',
      poolId: poolConfig.sousId,
    }
  })

export const fetchPoolHasWithdrawFee = async () => {
  const getWithdrawFee = await multicallWithOtherApi(abisForWithdrawFee, getWithdrawFeeCall)
  return getWithdrawFee.map((fee, i) => ({
    address: getWithdrawFeeCall[i].address,
    hasWithdrawFee: Number(fee[0]) === 0,
    poolId: getWithdrawFeeCall[i].poolId,
  }))
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: { stakingLimit: BigNumber; numberBlocksForUserLimit: number } }> => {
  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished && !p.isAutoCompounding)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['hasUserLimit', 'poolLimitPerUser', 'numberBlocksForUserLimit'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolStakingResultRaw = await multicallv2(sousChefV2, poolStakingCalls, { requireSuccess: false })
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return poolStakingChunkedResultRaw.reduce((accum, stakingLimitRaw, index) => {
    const hasUserLimit = stakingLimitRaw[0]
    const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
    const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
    return {
      ...accum,
      [validPools[index].sousId]: { stakingLimit, numberBlocksForUserLimit },
    }
  }, {})
}

const poolsWithV3 = poolsConfig.filter((pool) => pool?.version === 3)

export const fetchPoolsProfileRequirement = async (): Promise<{
  [key: string]: {
    required: boolean
    thresholdPoints: BigNumber
  }
}> => {
  const poolProfileRequireCalls = poolsWithV3
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['pancakeProfileIsRequested', 'pancakeProfileThresholdPoints'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolProfileRequireResultRaw = await multicallv2(sousChefV3, poolProfileRequireCalls, { requireSuccess: false })
  const chunkSize = poolProfileRequireCalls.length / poolsWithV3.length
  const poolStakingChunkedResultRaw = chunk(poolProfileRequireResultRaw.flat(), chunkSize)
  return poolStakingChunkedResultRaw.reduce((accum, poolProfileRequireRaw, index) => {
    const hasProfileRequired = poolProfileRequireRaw[0]
    const profileThresholdPoints = poolProfileRequireRaw[1]
      ? new BigNumber(poolProfileRequireRaw[1].toString())
      : BIG_ZERO
    return {
      ...accum,
      [poolsWithV3[index].sousId]: {
        required: hasProfileRequired,
        thresholdPoints: profileThresholdPoints.toJSON(),
      },
    }
  }, {})
}
